import $ from 'jquery';
import Popper from 'popper.js';
import Bootstrap from 'bootstrap';
import Parallax from 'parallax-js';
import Typed from 'typed.js';
import slick from 'slick-carousel';
import chart from './chart';
import select2 from 'select2';

// current page / nav current link
if ($('.current_page').length) {
    var namePage = $('.current_page').data('name');
    $("nav a").each(function () {
        var nameLink = $(this).data('name');
        if (namePage == nameLink) {
            $(this).addClass('active');
        }
    });
}

// header burger menu
$(function() {
    $('#toggle').click(function() {
        $(this).toggleClass('active');
        $('#overlay').toggleClass('open');
        $('body, html').toggleClass('lock');
        if($(window).innerHeight() < 480) {
            $('#overlay nav.overlay-menu').addClass('overflow');

        }else{
            $('#overlay nav.overlay-menu').removeClass('overflow');
        }
    });
});

// landscape
var mql = window.matchMedia("(orientation: portrait)");
mql.addListener(function(m) {
    if(m.matches) {
        $('body, html').removeClass('lock');
        $('.overlay').removeClass('open');
        $('.button_container').removeClass('active')
    }
    else {
        $('body, html').removeClass('lock');
        $('.overlay').removeClass('open');
        $('.button_container').removeClass('active');
    }
});

// main banner parallax
// runParallax();
// $(window).resize(function () {
//     runParallax();
// });
// function runParallax(){
//     var scene_text = document.getElementById('scene_text');
//     var scene_image = document.getElementById('scene_image');
//     var parallaxInstanceText = new Parallax(scene_text);
//     var parallaxInstanceImage = new Parallax(scene_image);
//     if ($(window).width() < 1024) {
//         parallaxInstanceText.destroy();
//         parallaxInstanceText = null;
//         parallaxInstanceImage.destroy();
//         parallaxInstanceImage = null;
//     }
// }

// main banner typed words
if ($('.typed').length) {
    var typed = new Typed('.typed_words', {
        strings: ["Hello!", "Hola!", "Sveiki!", "Cześć!", "Привет!", "Вiтаю!", "Сәлем!"],
        typeSpeed: 100,
        backSpeed: 100,
        backDelay: 100,
        startDelay: 100,
        loop: true
    });
}

// slider statistics
$('.slider').slick({
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                vertical: true,
                autoplay: false,
            }
        }
    ]
});

// globally map
$('.map path').mouseenter(function(e) {
    var self = $(this);
    if(self.data('name')){
        var countryName = self.data('name');
            var left = e.pageX;
            var top = e.pageY;
        if (self.data('name') === 'spain' || self.data('name') === 'usa' || self.data('name') === 'mexico' || self.data('name') === 'brazil' || self.data('name') === 'argentina') {
            $('.' + countryName).css('left', left + 100 + 'px');
        } else {
            $('.' + countryName).css('left', left - 300 + 'px');
        }
        $('.' + countryName).css('top', top - 300 + 'px');

        $('.map').toggleClass('overflow');
        $('.' + countryName).toggleClass('open');
    }
    }
);
$('.map path').mouseleave(function() {
        var self = $(this);
        if(self.data('name')){
            var countryName = self.data('name');
            $('.map').toggleClass('overflow');
            $('.' + countryName).toggleClass('open');
        }
    }
);

// accordion arrow
$('.card-header').click(function() {
    setTimeout(function () {
        $('#accordion .card').each(function () {
            if ($(this).find('.btn').attr('aria-expanded') === 'true') {
                $(this).find('.arrow').addClass('open');
                $(this).addClass('open');
            } else {
                $(this).find('.arrow').removeClass('open');
                $(this).removeClass('open');
            }
        });
    }, 100);
});

// technology, company mobile nav
$('.technology .mobile_content .nav-link, .company .mobile_content .nav-link').click(function () {
    var activeTab = $(this).text();
    $('#accordion .card-header__title').text(activeTab);
    $('#accordion .card-header__title').trigger('click');
});

// slider members mobile
$('.slider_members').slick({
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    prevArrow: '<div class="slick-arrow slick-prev"><svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M12.4219 25.731C12.6023 25.9103 12.8277 26 13.0757 26C13.3237 26 13.5491 25.9103 13.7295 25.731C14.0902 25.3724 14.0902 24.7897 13.7295 24.431L2.23188 13L13.7295 1.56897C14.0902 1.21034 14.0902 0.627586 13.7295 0.268966C13.3688 -0.0896552 12.7826 -0.0896552 12.4219 0.268966L0.270532 12.35C-0.0901766 12.7086 -0.0901766 13.2914 0.270532 13.65L12.4219 25.731Z" />\n' +
    '</svg>\n</div>',
    nextArrow: '<div class="slick-arrow slick-next"><svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M1.5781 25.731C1.39775 25.9103 1.1723 26 0.924316 26C0.676329 26 0.450886 25.9103 0.270531 25.731C-0.0901771 25.3724 -0.0901771 24.7897 0.270531 24.431L11.7681 13L0.270531 1.56897C-0.0901771 1.21034 -0.0901771 0.627586 0.270531 0.268966C0.63124 -0.0896552 1.21739 -0.0896552 1.5781 0.268966L13.7295 12.35C14.0902 12.7086 14.0902 13.2914 13.7295 13.65L1.5781 25.731Z" />\n' +
    '</svg>\n</div>'
});

// contact form
if ($('.contact_us').length) {
    var status = window.location.search.replace( '?', '');
    switch (status){
        case 'status=success':
            $('.mail_status').addClass('show');
            $('.mail_status').text('Message sent');
            break;
        case 'status=error_fiels':
            $('.mail_status').addClass('show');
            $('.mail_status').text('Check all fields');
            break;
        case 'status=error_mail':
            $('.mail_status').addClass('show');
            $('.mail_status').text('Send error');
            break;
    }
}

// topic select
$('.topic_select').select2();

// scroll
// if($('.careers').length){
//     var $page = $('html, body');
//     $('.main_button_link').click(function() {
//         $page.animate({
//             scrollTop: $($.attr(this, 'href')).offset().top
//         }, 500);
//         return false;
//     });
// }