import ApexCharts from 'apexcharts';


// graphics
if (document.querySelectorAll('.company').length) {

    var options_russia = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: false
        },
        colors: ['#3E80E4'], // color line
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        series: [{
            name: 'Traffic (year)',
            data: [10, 30, 20, 35, 30, 60],
        }],
        xaxis: {
            type: 'datetime',
            categories: ["2013", "2014", "2015", "2016", "2017", "2018"],
        },
        yaxis: {
            min: 0,
            max: 60,
            title: {
                text: 'm',
            },
        },
        tooltip: {
            x: {
                format: 'yyyy'
            },
            labels: ["Apple", "Mango", "Banana", "Papaya", "Orange"]
        }
    };

    initChart({
        selector: ["#chart_russia", "#chart_russia_mobile"],
        options: options_russia
    });

    var options_ukraine = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: false
        },
        colors: ['#3E80E4'], // color line
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        series: [{
            name: 'Traffic (year)',
            data: [10, 30, 20, 35, 30, 60],
        }],
        xaxis: {
            type: 'datetime',
            categories: ["2013", "2014", "2015", "2016", "2017", "2018"],
        },
        yaxis: {
            min: 0,
            max: 60,
            title: {
                text: 'm',
            },
        },
        tooltip: {
            x: {
                format: 'yyyy'
            },
            labels: ["Apple", "Mango", "Banana", "Papaya", "Orange"]
        }
    };

    initChart({
        selector: ["#chart_ukraine", "#chart_ukraine_mobile"],
        options: options_ukraine
    });

    var options_kazakhstan = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: false
        },
        colors: ['#3E80E4'], // color line
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        series: [{
            name: 'Traffic (year)',
            data: [10, 30, 20, 35, 30, 60],
        }],
        xaxis: {
            type: 'datetime',
            categories: ["2013", "2014", "2015", "2016", "2017", "2018"],
        },
        yaxis: {
            min: 0,
            max: 60,
            title: {
                text: 'm',
            },
        },
        tooltip: {
            x: {
                format: 'yyyy'
            },
            labels: ["Apple", "Mango", "Banana", "Papaya", "Orange"]
        }
    };

    initChart({
        selector: ["#chart_kazakhstan", "#chart_kazakhstan_mobile"],
        options: options_kazakhstan
    });

    var options_latvia = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: false
        },
        colors: ['#3E80E4'], // color line
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        series: [{
            name: 'Traffic (year)',
            data: [10, 30, 20, 35, 30, 60],
        }],
        xaxis: {
            type: 'datetime',
            categories: ["2013", "2014", "2015", "2016", "2017", "2018"],
        },
        yaxis: {
            min: 0,
            max: 60,
            title: {
                text: 'm',
            },
        },
        tooltip: {
            x: {
                format: 'yyyy'
            },
            labels: ["Apple", "Mango", "Banana", "Papaya", "Orange"]
        }
    };

    initChart({
        selector: ["#chart_latvia", "#chart_latvia_mobile"],
        options: options_latvia
    });

    var options_poland = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: false
        },
        colors: ['#3E80E4'], // color line
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        series: [{
            name: 'Traffic (year)',
            data: [10, 30, 20, 35, 30, 60],
        }],
        xaxis: {
            type: 'datetime',
            categories: ["2013", "2014", "2015", "2016", "2017", "2018"],
        },
        yaxis: {
            min: 0,
            max: 60,
            title: {
                text: 'm',
            },
        },
        tooltip: {
            x: {
                format: 'yyyy'
            },
            labels: ["Apple", "Mango", "Banana", "Papaya", "Orange"]
        }
    };

    initChart({
        selector: ["#chart_poland", "#chart_poland_mobile"],
        options: options_poland
    });

    var options_spain = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: false
        },
        colors: ['#3E80E4'], // color line
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        series: [{
            name: 'Traffic (year)',
            data: [10, 30, 20, 35, 30, 60],
        }],
        xaxis: {
            type: 'datetime',
            categories: ["2013", "2014", "2015", "2016", "2017", "2018"],
        },
        yaxis: {
            min: 0,
            max: 60,
            title: {
                text: 'm',
            },
        },
        tooltip: {
            x: {
                format: 'yyyy'
            },
            labels: ["Apple", "Mango", "Banana", "Papaya", "Orange"]
        }
    };

    initChart({
        selector: ["#chart_spain", "#chart_spain_mobile"],
        options: options_spain
    });

    var options_georgia = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: false
        },
        colors: ['#3E80E4'], // color line
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        series: [{
            name: 'Traffic (year)',
            data: [10, 30, 20, 35, 30, 60],
        }],
        xaxis: {
            type: 'datetime',
            categories: ["2013", "2014", "2015", "2016", "2017", "2018"],
        },
        yaxis: {
            min: 0,
            max: 60,
            title: {
                text: 'm',
            },
        },
        tooltip: {
            x: {
                format: 'yyyy'
            },
            labels: ["Apple", "Mango", "Banana", "Papaya", "Orange"]
        }
    };

    initChart({
        selector: ["#chart_georgia", "#chart_georgia_mobile"],
        options: options_georgia
    });

    function initChart(obj) {
        if (Array.isArray(obj.selector)){
            obj.selector.forEach(function (el) {
                var element = document.querySelector(el);
                if(element instanceof HTMLElement){
                    var chart = new ApexCharts(
                        element,
                        obj.options
                    );
                    chart.render();
                }
            })
        }
    }

}
